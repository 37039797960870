<section *ngIf="!loading">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/concurso/inscricao-aberta" title="{{'shared.concursos' | translate}}" class="link">{{'shared.concursos' | translate}}</a>
        </li>
        <li class="breadcrumb-item active">{{'ficha_inscricao.formulario_inscricao' | translate}}</li>
      </ol>
    </nav>
    <div class="alert alert-success">
      <h4>{{'ficha_inscricao.formulario_inscricao' | translate}}</h4>
      {{'ficha_inscricao.preencha_todos_dados' | translate}}
    </div>
    <div class="alert alert-warning">
      <h4>{{'shared.atencao' | translate}}</h4>
      {{'ficha_inscricao.instrucao_envio_anexos' | translate}}
    </div>
    <form [formGroup]="form">
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'shared.concurso' | translate}}</legend>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <input readonly="readonly" class="form-control" value="{{ concursoAndamento?.campus }}" type="text" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <input readonly="readonly" class="form-control" value="{{ concursoAndamento?.titulo }}" type="text" />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'ficha_inscricao_shared.dados_candidato' | translate}}</legend>
        <div class="row">
          <div class="col-sm-8" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.nome' | translate}}
                <span *ngIf="isFieldRequired('nome')">&nbsp;*</span>
              </span>
              <input (blur)="save('nome')" class="form-control" formControlName="nome" type="text" maxlength="80" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.data_nascimento' | translate}}
                <span *ngIf="isFieldRequired('dataNascimento')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('dataNascimento')"
                placeholder="dd/mm/aaaa"
                (change)="validaDataNascimento()"
                class="form-control datepicker"
                mask="00/00/0000"
                [dropSpecialCharacters]="false"
                formControlName="dataNascimento"
                type="text"
                maxlength="10" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.nome_social' | translate}}
                <span *ngIf="isFieldRequired('nomeSocial')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('nomeSocial')"
                class="form-control"
                formControlName="nomeSocial"
                type="text"
                maxlength="80" />
              <span class="input-group-text">
                <input
                  id="nSocial"
                  (change)="alteraNomeSocial()"
                  title="{{'ficha_inscricao_shared.utilizo_nome_social' | translate}}"
                  type="checkbox"
                  class="form-check-input"
                  [checked]="nomeSocial" />
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.nome_mae' | translate}}
                <span *ngIf="isFieldRequired('nomeMae')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('nomeMae')"
                class="form-control"
                formControlName="nomeMae"
                type="text"
                maxlength="80" />
              <span class="input-group-text">
                <input
                  (change)="alteraMaeDesconhecida()"
                  id="maeDesc"
                  title="{{'ficha_inscricao_shared.desconhecida' | translate}}"
                  type="checkbox"
                  class="form-check-input"
                  [checked]="nomeMae" />
              </span>
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.nome_pai' | translate}}
                <span *ngIf="isFieldRequired('nomePai')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('nomePai')"
                class="form-control"
                formControlName="nomePai"
                type="text"
                maxlength="80" />
              <span class="input-group-text">
                <input
                  (change)="alteraPaiDesconhecido()"
                  id="paiDesc"
                  title="{{'ficha_inscricao_shared.desconhecido' | translate}}"
                  type="checkbox"
                  class="form-check-input"
                  [checked]="nomePai" />
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.profissao' | translate}}
                <span *ngIf="isFieldRequired('profissao')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('profissao')"
                class="form-control"
                formControlName="profissao"
                type="text"
                maxlength="120" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.grau_escolaridade' | translate}}
                <span *ngIf="isFieldRequired('escolaridade')">&nbsp;*</span>
              </span>
              <select class="form-control" (blur)="save('escolaridade')" formControlName="escolaridade">
                <option value=""></option>
                <option *ngFor="let type of grauEscolaridade | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.sexo' | translate}}
                <span *ngIf="isFieldRequired('sexo')">&nbsp;*</span>
              </span>
              <select class="form-control" (blur)="save('sexo')" formControlName="sexo">
                <option value=""></option>
                <option *ngFor="let type of sexo | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.estado_civil' | translate}}
                <span *ngIf="isFieldRequired('estadoCivil')">&nbsp;*</span>
              </span>
              <select class="form-control" (blur)="save('estadoCivil')" formControlName="estadoCivil">
                <option value=""></option>
                <option *ngFor="let type of estadoCivil | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.estrangeiro' | translate}}
                <span *ngIf="isFieldRequired('estrangeiro')">&nbsp;*</span>
              </span>
              <select
                class="form-control"
                (blur)="save('estrangeiro')"
                (change)="validaEstrangeiro()"
                formControlName="estrangeiro">
                <option value=""></option>
                <option *ngFor="let type of estrangeiro | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">CPF
                <span *ngIf="isFieldRequired('cpf')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('cpf')"
                (change)="validaCPF()"
                class="form-control"
                mask="000.000.000-00"
                [dropSpecialCharacters]="false"
                formControlName="cpf"
                type="text"
                maxlength="14" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.passaporte' | translate}}
                <span *ngIf="isFieldRequired('passaporte')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('passaporte')"
                class="form-control passaporte"
                formControlName="passaporte"
                type="text" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">RNE
                <span *ngIf="isFieldRequired('rne')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('rne')"
                class="form-control"
                mask="AAAAAAA-A"
                [dropSpecialCharacters]="false"
                formControlName="rne"
                type="text"
                maxlength="9" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">RG
                <span *ngIf="isFieldRequired('rg')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('rg')"
                mask="A{13}"
                class="form-control"
                formControlName="rg"
                type="text"
                maxlength="13" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.uf' | translate}}
                <span *ngIf="isFieldRequired('uf')">&nbsp;*</span>
              </span>
              <select class="form-control" (blur)="save('uf')" formControlName="uf">
                <option value=""></option>
                <option *ngFor="let type of estado | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.data_expedicao' | translate}}
                <span *ngIf="isFieldRequired('dataExpedicao')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('dataExpedicao')"
                placeholder="dd/mm/aaaa"
                (change)="validaDataExpedicao()"
                class="datepicker form-control"
                mask="00/00/0000"
                [dropSpecialCharacters]="false"
                formControlName="dataExpedicao"
                type="text"
                maxlength="10" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.orgao' | translate}}
                <span *ngIf="isFieldRequired('orgaoExpedicao')">&nbsp;*</span>
              </span>
              <select class="form-control" (blur)="save('orgaoExpedicao')" formControlName="orgaoExpedicao">
                <option value=""></option>
                <option *ngFor="let type of orgaoExpedicaoRg | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'ficha_inscricao_shared.local_nascimento' | translate}}</legend>
        <div id="localNascimento">
          <div class="row">
            <div class="col-sm-4" style="margin-top: 0.5%">
              <div class="input-group">
                <span class="input-group-text">{{'ficha_inscricao_shared.pais' | translate}}
                  <span *ngIf="isFieldRequired('pais')">&nbsp;*</span>
                </span>
                <select
                  class="form-control"
                  (blur)="save('pais')"
                  formControlName="pais"
                  *ngIf="form.controls['estrangeiro'].value === 'NAO'">
                  <option value=""></option>
                  <option value="Brasil">Brasil</option>
                </select>
                <input
                  (blur)="save('pais')"
                  class="form-control"
                  formControlName="pais"
                  type="text"
                  maxlength="80"
                  *ngIf="form.controls['estrangeiro'].value !== 'NAO'" />
              </div>
            </div>
            <div class="col-sm-4" style="margin-top: 0.5%">
              <div class="input-group">
                <span class="input-group-text">{{'ficha_inscricao_shared.estado' | translate}}
                  <span *ngIf="isFieldRequired('estado')">&nbsp;*</span>
                </span>
                <select
                  class="form-control"
                  (blur)="save('estado')"
                  (change)="selecionaEstado()"
                  formControlName="estado"
                  *ngIf="form.controls['estrangeiro'].value === 'NAO'">
                  <option value=""></option>
                  <option *ngFor="let type of estado | keyvalue" [value]="type.key">
                    {{ type.value }}
                  </option>
                </select>
                <input
                  (blur)="save('estado')"
                  class="form-control"
                  id="estado"
                  formControlName="estado"
                  type="text"
                  maxlength="80"
                  *ngIf="form.controls['estrangeiro'].value !== 'NAO'" />
              </div>
            </div>
            <div class="col-sm-4" style="margin-top: 0.5%">
              <div class="input-group">
                <span class="input-group-text">{{'ficha_inscricao_shared.cidade' | translate}}
                  <span *ngIf="isFieldRequired('cidade')">&nbsp;*</span>
                </span>
                <select
                  class="form-control"
                  (blur)="save('cidade')"
                  formControlName="cidade"
                  *ngIf="form.controls['estrangeiro'].value === 'NAO'">
                  <option value=""></option>
                  <option
                    *ngFor="let cidade of cidades"
                    [attr.selected]="cidade === form.controls['cidade'].value ? true : null">
                    {{ cidade }}
                  </option>
                </select>
                <input
                  (blur)="save('cidade')"
                  class="form-control"
                  formControlName="cidade"
                  type="text"
                  maxlength="80"
                  *ngIf="form.controls['estrangeiro'].value !== 'NAO'" />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'ficha_inscricao_shared.informacoes_contato' | translate}}</legend>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.celular' | translate}}
                <span *ngIf="isFieldRequired('celular')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('celular')"
                class="form-control celular"
                mask="(00) 00000-0000"
                [dropSpecialCharacters]="false"
                formControlName="celular"
                type="text"
                maxlength="15" />
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.telefone' | translate}}
                <span *ngIf="isFieldRequired('telefone')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('telefone')"
                class="form-control telefone"
                mask="(00) 0000-0000"
                [dropSpecialCharacters]="false"
                formControlName="telefone"
                type="text"
                maxlength="14" />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <div class="form-control">
          <span class="input-group-text">{{'ficha_inscricao.endereco_brasil' | translate}}
            <span *ngIf="isFieldRequired('enderecoNacional')">&nbsp;*</span>
          </span>
          <select
            class="form-control"
            style="margin-top: 0.5%"
            (change)="alteraTipoEndereco()"
            formControlName="enderecoNacional"
            required>
            <option value="true">{{'shared.sim' | translate}}</option>
            <option value="false">{{'shared.nao' | translate}}</option>
          </select>
        </div>

        <legend class="w-auto">{{'ficha_inscricao_shared.endereco' | translate}}</legend>
        <div class="row">
          <div class="col-sm-4" style="margin-top: 0.5%">
            <ng-container
              *ngIf="
                form.controls.enderecoNacional.value === 'true';
                then contextoNacional;
                else contextoInternacional
              ">
            </ng-container>
            <ng-template #contextoNacional>
              <div class="input-group">
                <span class="input-group-text">{{'ficha_inscricao_shared.cep' | translate}}
                  <span *ngIf="isFieldRequired('enderecoCep')">&nbsp;*</span>
                </span>
                <input
                  class="form-control"
                  (blur)="save('enderecoCep'); validaCEP(enderecoNacional)"
                  mask="00.000-000"
                  [dropSpecialCharacters]="false"
                  formControlName="enderecoCep"
                  type="text"
                  maxlength="10" />
              </div>
            </ng-template>
            <ng-template #contextoInternacional>
              <div class="input-group">
                <span class="input-group-text">{{'ficha_inscricao_shared.cep' | translate}}
                  <span *ngIf="isFieldRequired('enderecoCep')">&nbsp;*</span>
                </span>
                <input
                  class="form-control"
                  (blur)="save('enderecoCep'); validaCEP(enderecoNacional)"
                  formControlName="enderecoCep"
                  type="text"
                  maxlength="10" />
              </div>
            </ng-template>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.logradouro' | translate}}
                <span *ngIf="isFieldRequired('enderecoLogradouro')">&nbsp;*</span>
              </span>
              <input
                (ch)="save('enderecoLogradouro')"
                class="form-control"
                formControlName="enderecoLogradouro"
                type="text"
                maxlength="80" />
            </div>
          </div>
          <div class="col-sm-2" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Nº
                <span *ngIf="isFieldRequired('enderecoNumero')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('enderecoNumero')"
                class="form-control"
                formControlName="enderecoNumero"
                type="text"
                maxlength="80" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.bairro' | translate}}
                <span *ngIf="isFieldRequired('enderecoBairro')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('enderecoBairro')"
                class="form-control"
                formControlName="enderecoBairro"
                type="text"
                maxlength="80" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.cidade' | translate}}
                <span *ngIf="isFieldRequired('enderecoCidade')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('enderecoCidade')"
                class="form-control"
                formControlName="enderecoCidade"
                type="text"
                maxlength="80" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.estado' | translate}}
                <span *ngIf="isFieldRequired('enderecoEstado')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('enderecoEstado')"
                class="form-control"
                formControlName="enderecoEstado"
                type="text"
                maxlength="2" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.complemento' | translate}}
                <span *ngIf="isFieldRequired('enderecoComplemento')">&nbsp;*</span>
              </span>
              <input
                (blur)="save('enderecoComplemento')"
                class="form-control"
                formControlName="enderecoComplemento"
                type="text"
                maxlength="80" />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'ficha_inscricao_shared.outros' | translate}}</legend>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.canhoto' | translate}}
                <span *ngIf="isFieldRequired('canhoto')">&nbsp;*</span>
              </span>
              <select class="form-control" (blur)="save('canhoto')" formControlName="canhoto">
                <option value=""></option>
                <option value="true">{{'shared.sim' | translate}}</option>
                <option value="false">{{'shared.nao' | translate}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.possui_deficiencia' | translate}}
                <span *ngIf="isFieldRequired('possuiDeficiencia')">&nbsp;*</span>
              </span>
              <select
                class="form-control"
                (blur)="save('possuiDeficiencia')"
                (change)="validaDeficiencia()"
                formControlName="possuiDeficiencia">
                <option value=""></option>
                <option value="true">{{'shared.sim' | translate}}</option>
                <option value="false">{{'shared.nao' | translate}}</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group" *ngIf="form.controls.possuiDeficiencia.value === 'true'">
              <ng-select
                (blur)="save('deficiencia')"
                [multiple]="true"
                formControlName="deficiencia"
                class="custom"
                style="width: 100%">
                <ng-option *ngFor="let type of deficiencia | keyvalue" [value]="type.key">{{ type.value }}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="form.controls.possuiDeficiencia.value === 'true'">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao.laudo_medico' | translate}}
                <span *ngIf="isFieldRequired('deficienciaLaudoMedico')">&nbsp;*</span>
              </span>
              <input
                class="form-control"
                [ngClass]="inputClassDeficienciaLaudoMedico"
                type="file"
                (change)="onFileSelected($event, 'deficienciaLaudoMedico')"
                #fileUpload
                formControlName="deficienciaLaudoMedico" />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="form.controls.possuiDeficiencia.value === 'true'">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.recursos_especiais' | translate}}
                <span *ngIf="isFieldRequired('recursos_especiais')">&nbsp;*</span>
              </span>
              <textarea class="form-control" formControlName="deficienciaNecessidadesProva"></textarea>
            </div>
            <p class="text-danger small">
              <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> {{'ficha_inscricao_shared.atendimento_sujeito_analise' | translate}}
            </p>
          </div>
        </div>
        <div class="row" *ngIf="concursoAndamento?.periodoReducao">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao.requerimento_reducao_taxa' | translate}}
                <span *ngIf="isFieldRequired('reducao')">&nbsp;*</span>
              </span>
              <select class="form-control" (change)="validaReducao()" formControlName="reducao">
                <option value=""></option>
                <option value="true">{{'shared.sim' | translate}}</option>
                <option value="false">{{'shared.nao' | translate}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" id="reducao_anexos" *ngIf="form.controls['reducao'].value === 'true'">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="alert alert-danger" style="margin-bottom: 0px">
              <p style="margin-bottom: 0px">
                {{'ficha_inscricao.termos_reducao_taxa' | translate}}
              </p>
            </div>
          </div>
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">
                <input type="checkbox" class="form-check-input" formControlName="reducaoTermo" />
              </span>
              <p class="form-control" style="height: 100%">
                {{'ficha_inscricao.aceite_termos_reducao_taxa' | translate}}
              </p>
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao.comprovante_estudante' | translate}}
                <span *ngIf="isFieldRequired('reducaoComprovanteEstudante')">&nbsp;*</span>
              </span>
              <input
                class="form-control"
                [ngClass]="inputClassComprovanteEstudante"
                type="file"
                (change)="onFileSelected($event, 'reducaoComprovanteEstudante')"
                #fileUpload
                formControlName="reducaoComprovanteEstudante" />
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao.comprovante_renda' | translate}}
                <span *ngIf="isFieldRequired('reducaoComprovanteRenda')">&nbsp;*</span>
              </span>
              <input
                class="form-control"
                [ngClass]="inputClassComprovanteRenda"
                type="file"
                (change)="onFileSelected($event, 'reducaoComprovanteRenda')"
                #fileUpload
                formControlName="reducaoComprovanteRenda" />
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="border rounded-3 p-2" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">
        <legend class="w-auto" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">{{'ficha_inscricao_shared.autodeclaracao' | translate}}</legend>
        <div class="row" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group" style="margin-top: 0.5%">
              <span class="input-group-text" style="margin-top: 0.5%">
                {{'ficha_inscricao_shared.declara_cotista' | translate}}
                <span *ngIf="isFieldRequired('autodeclaraRaca')">&nbsp;*</span>
              </span>
              <select
                style="margin-top: 0.5%"
                class="form-control"
                (blur)="save('autodeclaraRaca')"
                (change)="validaAutodeclaraRaca()"
                formControlName="autodeclaraRaca"
                required>
                <option value="true">{{'shared.sim' | translate}}</option>
                <option value="false">{{'shared.nao' | translate}}</option>
              </select>
            </div>
          </div>
        </div>
        <div
          class="col-sm-6"
          style="margin-top: 0.5%"
          *ngIf="form.controls.autodeclaraRaca.value && tipoConcurso?.localeCompare('TECADM') === 0">
          <span class="input-group-text" style="margin-top: 0.5%"> {{'ficha_inscricao_shared.me_auto_declaro_pessoa' | translate}}: 
            <span *ngIf="isFieldRequired('autodeclaracao')">&nbsp;*</span>
          </span>
          <div class="input-group" style="margin-top: 0.5%">
            <select class="form-control" (blur)="save('autodeclaracao')" formControlName="autodeclaracao" required>
              <option value="PRETO">{{'ficha_inscricao_shared.preta' | translate}}</option>
              <option value="PARDO">{{'ficha_inscricao_shared.parda' | translate}}</option>
              <option value="INDIGENA">{{'ficha_inscricao_shared.indigena' | translate}}</option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="form.controls.autodeclaraRaca.value && tipoConcurso?.localeCompare('TECADM') === 0">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text"
                >{{'ficha_inscricao_shared.deseja_utilizar_sistema_pontuacao' | translate}}
                <span *ngIf="isFieldRequired('pontuacaoDiferenciada')">&nbsp;*</span>
              </span>
              <select
                class="form-control"
                (blur)="save('pontuacaoDiferenciada')"
                formControlName="pontuacaoDiferenciada"
                required>
                <option value="true" data-bs-toggle="modal" data-bs-target="#dialogConcluirModal">{{'shared.sim' | translate}}</option>
                <option value="false">{{'shared.nao' | translate}}</option>
              </select>
            </div>
            <!-- Modal -->
            <div
              class="modal fade"
              id="dialogConcluirModal"
              tabindex="-1"
              aria-labelledby="dialogConcluirModalLabel"
              aria-hidden="true"
              data-bs-backdrop="static">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="dialogConcluirModalLabel">{{'shared.atencao' | translate}}</h1>
                  </div>
                  <div class="modal-body">
                    {{'ficha_inscricao_shared.efetivar_participacao_cotista' | translate}}
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{{'ficha_inscricao_shared.estou_ciente' | translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12"
          style="margin-top: 0.5%"
          *ngIf="form.controls.autodeclaraRaca.value && tipoConcurso?.localeCompare('TECADM') === 0">
          <div class="input-group" *ngIf="form.controls.autodeclaraRaca.value">
            <span class="input-group-text">
              <input
                type="checkbox"
                class="form-check-input"
                formControlName="declaracaoVeracidade"
                [required]="true" />
            </span>
            <p class="form-control" style="height: 100%">
              {{'ficha_inscricao_shared.declaracao_veracidade' | translate}}
            </p>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2" *ngIf="concursoAndamento?.temas?.length">
        <legend class="w-auto">{{'ficha_inscricao_shared.pontos' | translate}}</legend>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="col-sm-12">
              <div class="row">
                <div class="input-group">
                  <ng-select [multiple]="true" formControlName="temas" class="custom" style="width: 100%">
                    <ng-option *ngFor="let value of concursoAndamento?.temas" [value]="value">{{ value }}</ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-sm-12" style="margin-top: 0.5%">
              <p class="text-danger small">
                <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> {{'ficha_inscricao.prova_didatica' | translate}}
              </p>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'ficha_inscricao.identificacao_para_acesso' | translate}}</legend>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Email
                <span *ngIf="isFieldRequired('email')">&nbsp;*</span>
              </span>
              <input
                class="form-control"
                (keyup)="lowerCase('email')"
                (blur)="save('email')"
                type="email"
                formControlName="email"
                [email]="true"
                maxlength="50" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao.senha' | translate}}
                <span *ngIf="isFieldRequired('senha')">&nbsp;*</span>
              </span>
              <input class="form-control" type="password" formControlName="senha" maxlength="64" />
            </div>
          </div>
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'shared.confirmacao' | translate}}
                <span *ngIf="isFieldRequired('senhaConfirmacao')">&nbsp;*</span>
              </span>
              <input class="form-control" type="password" formControlName="senhaConfirmacao" maxlength="64" />
            </div>
          </div>
          <div class="col-sm-12" style="margin-top: 0.5%">
            <p class="text-danger small">
              <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> {{'senha_shared.senha_deve_conter' | translate}}
            </p>
            <p class="text-danger small">
              <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> {{'ficha_inscricao.identificacao_para_acesso' | translate}}
            </p>
            <p class="text-danger small">
              <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> {{'ficha_inscricao.nao_lembra_senha' | translate}}
              <a routerLink="/sistema/senha-recuperar">{{'ficha_inscricao_shared.clique_aqui' | translate}}</a> {{'ficha_inscricao.para_recuperar' | translate}}
            </p>
          </div>
        </div>
      </fieldset>
      <div class="row" style="margin-top: 20px" *ngIf="conferencia === false">
        <div class="col-6">
          <a (click)="voltar()" class="btn btn-secondary btn-sm"
            ><i class="bi bi-arrow-left-short" aria-hidden="true">&nbsp;</i> <span>{{'shared.voltar' | translate}}</span></a
          >
        </div>
        <div class="col-6 d-flex flex-row-reverse">
          <button (click)="conferir()" class="btn btn-primary btn-sm">
            <i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i> {{'shared.enviar' | translate}}
          </button>
        </div>
      </div>
      <div class="row" style="margin-top: 20px" *ngIf="conferencia === true">
        <div class="col-6">
          <a (click)="cancelar()" class="btn btn-secondary btn-sm"
            ><i class="bi bi-pencil" aria-hidden="true">&nbsp;</i> <span>{{'shared.alterar' | translate}}</span></a
          >
        </div>
        <div class="col-6 d-flex flex-row-reverse">
          <button (click)="gravar()" class="btn btn-success btn-sm">
            <i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i> {{'shared.confirmar' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</section>